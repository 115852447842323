<template>
  <section id="section-schedule" aria-label="section-services-tab">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3 text-center">
          <h1>Програма</h1>
          <div class="spacer-single"></div>
          <div class="separator">
            <br>
          </div>
          <div class="spacer-single"></div>
        </div>

        <div class="col-md-12">
          <div class="de_tab tab_style_4 text-center">
            <div class="de_tab_content text-left">
              <div id="tab1" class="tab_single_content dark">
                <div class="row">
                  <div class="col-md-12">
                    <div v-for="(schedule, index) in schedule" :key="index">
                      <a
                        :href="schedule.presentationLink"
                        target="_blank"
                        v-if="schedule.presentationLink"
                      >
                        <div class="schedule-listing selectable">
                          <div class="schedule-item">
                            <div class="sc-time">{{ schedule.time }}</div>
                            <div class="sc-info">
                              <h3>{{ schedule.eventName }}</h3>
                              <p>
                                {{ schedule.eventDesc }}
                              </p>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </a>
                      <div v-else class="schedule-listing">
                        <div class="schedule-item">
                          <div class="sc-time"><p>{{ schedule.time }}</p>
                          <div class="sc-Iconnn"><img :src="schedule.eventIcon" /></div>
                          </div>
                          <div class="sc-info">
                            <h3>{{ schedule.eventName }}</h3>
                            <p v-html="schedule.eventDesc"></p>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import scheduleObject from "../util/pageTexts/schedule.js";

export default {
  name: "Schedule",
  data() {
    return {
      scheduleObject,
      scheduleDay: "day1",
    };
  },
  methods: {
    setScheduleDay(scheduleDay) {
      this.scheduleDay = scheduleDay;
    },
    getArray(object) {
      const array = [];
      Object.keys(object).forEach((key) => {
        array.push(object[key]);
      });
      return array;
    },
  },
  computed: {
    schedule() {
      return this.scheduleObject[this.scheduleDay];
    },
  },
};
</script>

<style scoped>
h4,
p,
span,
h1,
h3 {
  font-family: Comfortaa;
  text-transform: initial;
  letter-spacing: initial;
}

.sc-info {
  width: 70%;
}

.sc-Iconnn{
  width: 20%;
  height: 20%;
  align-items: center;
  display: flex;
}
.sc-Iconnn img{
  width: 100%;
  height: 100%;
}


.sc-time {
  width: 30%;
  text-align: center;
  font-size: larger;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}
.sc-time p{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  margin: 0;
}
.sc-info h3 {
  color: var(--secondary-color-1)
}

@media only screen and (max-width: 760px) {
.sc-Iconnn{
  width: 50%;
  height: 50%;

}
}
</style>
