import projects from "./pageTexts/projects";

export default (projectIndex) => {
  return (
    projects.at(projectIndex) || {
      name: "",
      github: "",
      description: "",
      participants: [],
      website: "",
      type: "",
      category: "",
    }
  );
};
