import { createStore } from "vuex";
import loading from "./loading";
import projects from "./projects";
//import voting from "./voting";
import isMobile from "./isMobile";

export default createStore({
  modules: {
    loading,
    projects,
   // voting,
    isMobile
  },
});
