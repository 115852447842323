<template>
  <Loading v-if="this.$store.getters.isLoading" />
  <div id="HomePage" v-else>
    <Privacy />
    <div class="MainImage" style="background-size: cover">
      <div class="CenterText">
        <p class="MainTitle"><span>TUES</span><br /><span style="color: #75659f">Fest</span></p>
        <h1 class="SubTitle">Fair from the future</h1>
        <div class="Text">
          <p><i class="icons fa fa-calendar" aria-hidden="true"></i>16.04.2022</p>
          <a
            class="yt_link"
            target="_blank"
            rel="nofollow noreferer"
            href="https://www.google.com/maps/place/%D0%A4%D0%BE%D1%80%D1%83%D0%BC+%22%D0%94%D0%B6%D0%BE%D0%BD+%D0%90%D1%82%D0%B0%D0%BD%D0%B0%D1%81%D0%BE%D0%B2%22,+%D0%A1%D0%BE%D1%84%D0%B8%D1%8F+%D0%A2%D0%B5%D1%85+%D0%9F%D0%B0%D1%80%D0%BA/@42.6667592,23.3745936,18.17z/data=!4m12!1m6!3m5!1s0x40aa85d643a3af15:0xcbc66ddbb54d08b3!2z0KLQtdGFINCf0LDRgNC6INCh0L7RhNC40Y8!8m2!3d42.6675837!4d23.3734022!3m4!1s0x40aa8679286c7ed7:0x4a9d96383bfc10e5!8m2!3d42.6663699!4d23.375082"
          >
            <i class="icons fa fa-map-marker" aria-hidden="true"></i>София Тех Парк - Джон Атанасов
          </a>
        </div>
      </div>
    </div>
    <Countdown />
    <AboutEvent />
    <Sponsors />
    <Features />
    <Schedule />
    <Testimonials />
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import Countdown from "../components/Countdown.vue";
import Sponsors from "../components/Sponsors.vue";
import Testimonials from "../components/Testimonials.vue";
import Features from "../components/Features.vue";
import AboutEvent from "../components/AboutEvent.vue";
import Schedule from "../components/Schedule.vue";
import Privacy from "../components/Privacy.vue";

export default {
  name: "HomePage",
  components: {
    Loading,
    Countdown,
    Sponsors,
    Testimonials,
    Features,
    AboutEvent,
    Schedule,
    Privacy,
  },
};
</script>

<style scoped>
#HomePage {
  margin-top: 0px !important;
}

.MainImage {
  height: auto;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  background-position: 0% 0%, center calc();
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  background-image: linear-gradient(rgba(255, 0, 0, 0.089), rgba(0, 0, 255, 0.123)),
    url("@/../public/assets/main_background.svg");
  background-size: cover;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

.MainTitle {
  font-size: 8vw;
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 2vh;
}

.MainTitle,
.SubTitle {
  background-color: unset;
  font-family: Warzone97;
  line-height: normal;
  text-transform: none;
}

.Text {
  margin-top: 2vh;
}

.Text,
.Text a {
  font-size: 2rem;
}

.youtube-icon {
  color: rgb(216, 24, 24);
}

.icons {
  display: inline-block;
  margin-right: 3px;
}
</style>
