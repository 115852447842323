<template>
  <section id="section-features">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3 text-center">
          <h1>На <span class="colored-text">ТУЕС ФЕСТ</span> очаквайте</h1>
          <div class="separator">
            <br>
          </div>
          <div class="spacer-single"></div>
        </div>

        <div class="col-lg-6">
          <div class="box-number square">
            <i class="bg-color hover-color-2 fa fa-code text-light"></i>
            <div class="text">
              <h3><span>Интригуващи проекти</span></h3>
              <p>
                Разгледайте най-добрите технологични проекти на учениците от ТУЕС в сайта и на
                livestream програмата на ТУЕС Фест.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-wow-delay=".25s">
          <div class="box-number square">
            <i class="bg-color hover-color-2 fa fa-balance-scale text-light"></i>
            <div class="text">
              <h3><span>Онлайн гласуване</span></h3>
              <p>
                Гласувайте онлайн за проекта, впечатлил Ви най-много, за да му помогнете да спечели
                наградата на публиката!
              </p>
            </div>
          </div>
        </div>
        <div class="spacer-single sm-hide"></div>
        <div class="col-lg-6" data-wow-delay=".5s">
          <div class="box-number square">
            <i class="bg-color hover-color-2 fa fa-question text-light"></i>
            <div class="text">
              <h3><span>Въпроси и отговори</span></h3>
              <p>
                Задавайте своите въпроси, свързани с училището, а ние ще отговорим! Ще можете да се
                включите в онлайн дискусиите и да се срещнете виртуално със създателите на проектите
                в ТУЕС Фест.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-wow-delay=".75s">
          <div class="box-number square">
            <i class="bg-color hover-color-2 fa fa-graduation-cap text-light"></i>
            <div class="text">
              <h3><span>За училището</span></h3>
              <p>
                Научете повече за специалния образователен модел на ТУЕС и какво прави училището
                толкова успешно.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-wow-delay="1s">
          <div class="box-number square">
            <i class="bg-color hover-color-2 fa fa-industry text-light"></i>
            <div class="text">
              <h3><span>Връзка с бизнеса</span></h3>
              <p>
                Научете повече за тясната връзка с ИТ бизнеса, която е ключова за ТУЕС. Чуйте
                мнението и на бизнес партньорите на училището.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
h1,
span,
p {
  text-transform: initial;
  letter-spacing: initial;
  font-family: Comfortaa;
}

p {
  color: white;
}

i {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
