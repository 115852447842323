<template>
  <section id="register-vote-section">
    <div class="RegisterVotePage">
      <span>{{
        !voteRegistered
          ? "Гласът за следните отбори е успешно регистриран"
          : "Вече е използван този глас"
      }}</span>
      <ul>
        <li v-for="(projectId, index) in projects" :key="index">
          {{ getProject(projectId).name }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import getProjectIndexById from "../util/getProjectIndexById";
import getProjectInfo from "../util/getProjectInfo";
import axios from "axios";

export default {
  name: "RegisterVotePage",
  methods: {
    getProject(id) {
      return getProjectInfo(getProjectIndexById(id));
    },
  },
  data() {
    return {
      projects: [],
      voteRegistered: true,
      errors: null,
    };
  },
  async mounted() {
    try {
      const {
        data: {
          response: { projects, voteRegistered },
        },
      } = await axios.patch(`https://server.tuesfest.bg/api/project/vote/${this.$route.params.voteId}`);

      this.projects = projects;
      this.voteRegistered = voteRegistered;
    } catch (err) {
      this.errors = err;
    }
  },
};
</script>

<style scoped>
span,
li {
  color: white;
  font-family: Comfortaa !important;
  letter-spacing: initial;
  font-weight: bold;
  font-size: 1rem;
}

.RegisterVotePage {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

ul {
  text-align: left;
}

</style>
