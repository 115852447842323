import getters from "./getters.js";
import mutations from './mutations.js';
import projects from '@/util/pageTexts/projects';
import shuffleArray from "@/util/shuffleArray.js";

export default {
  state() {
    return {
      projects: shuffleArray(shuffleArray(projects)),
      currentProjectCategory: "Robotics"
    };
  },
  getters,
  mutations
};