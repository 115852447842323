<template>
  <section
    id="section-countdown"
    aria-label="section"
    class="gradient-to-right pt40 pb40"
    style="background-size: cover"
  >
    <div class="container" style="background-size: cover">
      <div class="row" style="background-size: cover">
        <div class="col-md-10 offset-md-1" style="background-size: cover">
          <div class="spacer-single" style="background-size: cover"></div>
          <div id="defaultCountdown" class="is-countdown" style="background-size: cover">
            <span :class="`countdown-row countdown-show${Object.keys(times).length}`"
              ><span
                class="countdown-section"
                v-for="(timeKey, index) in Object.keys(times)"
                :key="index"
                ><span class="countdown-amount">{{ times[timeKey].time }}</span
                ><span class="countdown-period">{{
                  times[timeKey].time != 1
                    ? times[timeKey].text.plural
                    : times[timeKey].text.singular
                }}</span></span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CountDown",
  data() {
    return {
      countDownDate: new Date("Apr 16, 2022 10:30:0").getTime(),
      times: {
        days: {
          time: 0,
          text: {
            singular: "Ден",
            plural: "Дни",
          },
        },
        hours: {
          time: 0,
          text: {
            singular: "Час",
            plural: "Часа",
          },
        },
        minutes: {
          time: 0,
          text: {
            singular: "Минута",
            plural: "Минути",
          },
        },
        seconds: {
          time: 0,
          text: {
            singular: "Секунда",
            plural: "Секунди",
          },
        },
      },
    };
  },
  methods: {
    updateTimer() {
      let now = new Date().getTime();
      let distance = this.countDownDate - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        days = 0;
        hours = 0;
        minutes = 0;
        seconds = 0;
      }

      this.times.days.time = days;
      this.times.hours.time = hours;
      this.times.minutes.time = minutes;
      this.times.seconds.time = seconds;
    },
  },
  created() {
    this.updateTimer();
    this.timer = setInterval(this.updateTimer, 100);
  },
};
</script>
