<template>
  <section class="speaker-detail" id="section-about">
    <div class="auto-container pb10">
      <div class="content-box">
        <h1>Упс, страницата, която търсите още не е излязла от фабриката на Гришо &#128296;</h1>
      </div>
    </div>
    <div class="auto-container-ht">
      <div class="image-with-caption">
        <a href="https://elsys-bg.org/priem/red-i-uslovija-za-priem" target="_blank">
          <img alt="" src="/assets/not-found.svg" class="img-fluid rounded mb30" />
        </a>
      </div>
    </div>
  </section>
</template>
