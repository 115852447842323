import { createRouter, createWebHistory } from "vue-router";
import routes from "../util/routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes.filter((e) => e.component),
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    if(savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.afterEach((route) => {
  const { scrollTo } = route.meta;
  setTimeout(() => {
    if (scrollTo) {
      document.getElementById(scrollTo).scrollIntoView();
    }
  });
});

export default router;
