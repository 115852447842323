<template>
  <div :class="`Index ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <Navbar v-if="!$store.getters.isLoading" />
    <!-- <vote v-if="this.$route.fullPath == '/projects'" /> -->
    <main>
      <router-view />
    </main>
    <Footer v-if="!$store.getters.isLoading" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
//import Vote from "./components/Vote.vue";

export default {
  name: "HomePage",
  data() {
    return {
      loading: false,
    };
  },
  components: {
    Navbar,
    Footer,
  //  Vote,
  },
};
</script>

<style>
#main {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: Comfortaa;
}

</style>
