export default {
  day1: [
    {
      time: "10:30 - 10:45",
      eventName: "Откриване",
      eventDesc: "Началото на TUES Fest 2022",
      eventIcon: "/assets/icons-schedule/start-button.png",
    },
    {
      time: "10:45 - 16:00",
      eventName: "Изложение на ученически проекти",
      eventDesc: `
      След официалното откриване всеки посетител ще има възможността:
      <ul>
        <li>Да разгледа над 90 ученически проекта и да поговори с техните създатели</li>
        <li>Да се запознае със спонсорите на събитието</li>
        <li>Да говори с членове на извънкласни клубове в ТУЕС и с други ученици от училището</li>
        <li>Да гласува за любимия си ученически проект</li>
        <li>Да участва в Treasure Hunt игра с интересни задачи и награди</li>
      </ul>
      `,
      eventIcon: "/assets/icons-schedule/testing.png",
    },
    {
      time: "12:00 - 13:00",
      eventName: "Панел “Пътеводител за оцеляване в ТУЕС”",
      eventDesc: `
      По време на този панел ще видите:
      <ul>
        <li>Ученици от всеки випуск, обучаващ се в момента</li>
        <li>С какво и как им помага обучението в ТУЕС</li>
        <li>С какво може да се занимава един туесар освен с компютри</li>
      </ul>
      `,
      eventIcon: "/assets/icons-schedule/adventure.png",
    },
    {
      time: "13:30 - 14:15",
      eventName: "Лекция “ТУЕС - в какво се забърках” с Кирил Митов",
      eventDesc: `
      Презентацията ще ви разкаже за:
      <ul>
        <li>Предизвикателствата на обучението в ТУЕС, динамиката на ИТ света и борбата на един инженер</li>
        <li>Как знанията по физика, математика, електротехника и др се преплитат, за да се стане възможно създаването на съвременните технологични продукти, които всички днес приемаме за даденост</li>
        <li>Какъв е пътят на един туесар след ТУЕС</li>
      </ul>
      `,
      eventIcon: "/assets/icons-schedule/problem.png",
    },
    {
      time: "14:15 - 16:00",
      eventName: "Изложение на проекти",
      eventDesc: "Последна възможност да разгледате проектите на нашите съученици и да гласувате за любимия си преди края на изложението в 16:00",
      eventIcon: "/assets/icons-schedule/development.png",
    },
    {
      time: "16:15-18:00",
      eventName: "Награждаване",
      eventDesc: "Провеждане на церемонията по награждаване и закриваща церемония",
      eventIcon: "/assets/icons-schedule/success.png",
    }
  ],
};
