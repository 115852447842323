<template>
  <section
    id="sponsors"
    class="text-white"
    data-bgimage="url(images-event/bg/2.png) fixed center no-repeat"
  >
    <div class="container">
      <div class="text-center">
        <h1>Спонсори</h1>
        <div class="separator">
          <br>
        </div>
        <div class="spacer-single"></div>
      </div>

      <div class="clearfix"></div>

      <div class="sponsors-outer sponsor" v-for="(sponsorKey, index) in Object.keys(sponsors)" :key="index">
        <div class="text-center-sub text-center">
          <h1 class="sponsor-title">{{sponsors[sponsorKey].name}}</h1>
        </div>
        <a :href="sponsor.site" target="_blank" rel="nofollow" class="translate_sponsor" v-for="(sponsor, index) in sponsors[sponsorKey].items" :key="index">
          <div class="team-pic">
            <img
              :src="sponsors[sponsorKey].folder + sponsor.logoSrc + '?v=' + Date.now()"
              class="img-responsive image"
              :alt="sponsor.company"
              :style="`padding: ${sponsor.padding || '1.2vw'};`"
            />
          </div>
        </a>
        <div class="clearfix"></div>
        <div class="spacer-double"></div>
      </div>
    </div>
  </section>
</template>

<script>
import sponsors from "../util/pageTexts/sponsors";

export default {
  name: "Sponsors",
  data() {
    return {
      sponsors,
    };
  },
};
</script>

<style scoped>
h3,
p,
h2,
h1,
h4 {
  text-transform: initial !important;
  font-family: Comfortaa !important;
  letter-spacing: initial !important;
}
</style>
