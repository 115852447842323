import HomePage from "../pages/HomePage.vue";
import AboutPage from "../pages/AboutPage.vue";
import ApplyPage from "../pages/ApplyPage.vue";

import NotFoundPage from "../pages/NotFoundPage.vue";
import ProjectsPage from "../pages/ProjectsPage.vue";
import SingleProjectPage from "../pages/SingleProjectPage.vue";
import RegisterVotePage from '../pages/RegisterVotePage.vue';

export default [
  {
    path: "/",
    component: HomePage,
  },
  /*{
    path: "/live",
    linkText: "Гледайте записи от събитието",
  },*/
  {
    path: "/projects",
    linkText: "Проекти",
    component: ProjectsPage,
  },
  {
    path: "/projects/:index",
    component: SingleProjectPage,
  },
  {
    path: "/about",
    linkText: "За ТУЕС",
    component: AboutPage,
  },
  {
    path: "/apply",
    linkText: "Кандидатстване",
    component: ApplyPage,
  },
  {
    path: "/schedule",
    linkText: "Програма",
    meta: {
      scrollTo: "section-schedule"
    },
    component: HomePage,
  },
  {
    path: "/vote/:voteId",
    component: RegisterVotePage
  },
  {
    path: "/:notFound(.*)",
    component: NotFoundPage,
  },
];
