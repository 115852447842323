<template>
  <footer class="style-2">
    <div class="container">
      <div class="row align-items-middle">
        <div class="col-md-3">
          <a href="https://elsys-bg.org/" target="_blank">
            <img src="/assets/elsys.svg" class="logo-small" alt="" /><br />
          </a>
        </div>

        <div class="col-md-6">
          &copy; Copyright 2022 Всички права запазени.
          <a href="https://elsys-bg.org/" target="_blank"
            ><span class="id-color-2"
              >Технологично училище Електронни системи към Технически Университет - София</span
            ></a
          >
        </div>

        <div class="col-md-3 text-right">
          <div class="social-icons">
            <a target="_blank" href="https://www.facebook.com/tues.bg"
              ><i class="fa fa-facebook fa-lg"></i
            ></a>
            <a target="_blank" href="https://www.instagram.com/tues.bg/"
              ><i class="fa fa-instagram fa-lg"></i
            ></a>
            <a target="_blank" href="https://www.youtube.com/channel/UCQcbYkAKPEgfjzvwb2sUWSQ"
              ><i class="fa fa-youtube-play fa-lg"></i
            ></a>
            <a target="_blank" href="https://elsys-bg.org"
              ><i class="fa fa-info-circle fa-lg"></i
            ></a>
            <a href="mailto:tuesfest@elsys-bg.org"><i class="fa fa-envelope-o fa-lg"></i></a>
          </div>
        </div>
      </div>
    </div>

    <a href="#" id="back-to-top" class="custom-1"></a>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style scoped>
.id-color-2 {
  color: var(--secondary-color-1);
}

i:hover {
  background-color: var(--primary-color-1);
}
</style>
