<template>
  <section
    id="project-section"
    v-if="projectExists"
    class="project-section error-section event-detail apply-section"
  >
    <div class="auto-container-ht">
      <div class="content-box info-text">
        <div class="text-upper">
          <h1 class="upper-name">{{ name }}</h1>
        </div>
        <div class="project-image-box">
          <iframe v-if="youtube" allowfullscreen class="info-video" :src="youtube"></iframe>
          <iframe
            v-else-if="presentation"
            allowfullscreen
            class="info-video"
            src="{{presentation}}"
          >
          </iframe>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
          class="project-vote"
        >
          <!--<div class="actions-links">
            <a href="/vote" class="actions-vote text-white theme-btn btn-style-three"
              ><span class="btn-title">Гласувай!</span></a
            >
          </div>
          -->
          <a
            :href="website"
            v-if="website"
            target="_blank"
            rel="noopener"
            class="actions-vote theme-btn btn-style-one"
          >
            <span class="btn-title">Сайт на проекта</span>
          </a>
          <section id="section-portfolio" class="mt20 no-top no-bottom" aria-label="section">
            <div
              id="gallery"
              class="gallery zoom-gallery full-gallery de-gallery pf_full_width"
              data-wow-delay=".3s"
            >
              <div v-for="(picture, index) in pictures" :key="index" class="item residential">
                <div class="picframe" style="background-size: cover">
                  <router-link style="width: 100%; height: 100%" :to="`/assets/project-pictures/${picture}.webp`" target="_blank" title="">
                    <span class="overlay">
                      <span class="pf_text">
                        <span class="project-name">Виж снимката</span>
                      </span>
                    </span>
                    <img :src="`/assets/project-pictures/${picture}.webp`" alt="" />
                  </router-link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <br /><br />
      <div class="content-box">
        <div class="text-description">
          <div class="text-upper">
            <h4 class="upper-name">Изготвили проекта</h4>
          </div>
          <ul class="description-list list-style-three">
            <li v-for="(participant, index) in participants" :key="index">{{ participant }}</li>
          </ul>
          <a
            v-if="github"
            style="display: inline-block; padding-bottom: 5px"
            class="id-color github-link theme-btn btn-style-fou"
            :href="github"
            target="_blank"
            rel="noopener"
          >
            <i style="padding-right: 5px" class="fa fa-github fa-lg"></i
            ><span class="btn-title">Github хранилище</span>
          </a>
          <br />
          <a
            v-if="website"
            style="display: inline-block; padding-bottom: 5px"
            class="id-color github-link theme-btn btn-style-four"
            :href="website"
            target="_blank"
            rel="noopener"
          >
            <i style="padding-right: 5px" class="fa fa-link fa-lg"></i
            ><span class="btn-title">Линк към уебсайт на проекта</span>
          </a>

          <br />

          <div v-if="type" class="text-description">
            {{ type }}
          </div>

          <br />

          <div class="text-description">
            <h4>Описание на проекта</h4>
            {{ description }}
          </div>

          <br /><br />
        </div>
      </div>
      <div class="project-buttons">
        <router-link
          v-if="showPrev"
          :to="`/projects/${prev}`"
          class="btn-custom-1 mr10 btn-resp text-white sm-mb-30 scroll-to"
          >Към предишния проект</router-link
        >

        <router-link
          v-if="showNext"
          :to="`/projects/${next}`"
          class="btn-custom-1 btn-resp text-white sm-mb-30 scroll-to"
          >Към следващия проект</router-link
        >
      </div>
    </div>
  </section>
  <NotFound v-else />
</template>

<script>
import getProjectInfo from "../util/getProjectInfo";
import projects from "../util/pageTexts/projects";
import NotFound from "./NotFoundPage.vue";

export default {
  name: "SingleProjectPage",
  data() {
    return {
      name: "",
      github: "",
      description: "",
      participants: "",
      website: "",
      type: "",
      category: "",
      youtube: "",
      pictures: [],
      presentation: "",
    };
  },
  components: {
    NotFound,
  },
  methods: {
    setProject() {
      const {
        name,
        github,
        description,
        participants,
        website,
        type,
        category,
        youtube,
        pictures,
        presentation,
      } = getProjectInfo(this.$route.params.index);
      this.name = name;
      this.github = github;
      this.description = description;
      this.participants = participants;
      this.website = website;
      this.type = type;
      this.category = category;
      this.youtube = youtube;
      this.pictures = pictures;
      this.presentation = presentation;
    },
  },
  computed: {
    showPrev() {
      return this.$route.params.index != 0;
    },
    showNext() {
      return this.$route.params.index != projects.length - 1;
    },
    next() {
      return new Number(this.$route.params.index) + 1;
    },
    prev() {
      return new Number(this.$route.params.index) - 1;
    },
    projectExists() {
      return this.$route.params.index >= 0 && this.$route.params.index <= projects.length - 1;
    },
  },
  mounted() {
    this.setProject();
  },
  updated() {
    this.setProject();
  },
};
</script>

<style scoped>
h3,
p,
h2,
h1,
h4 {
  text-transform: initial;
  font-family: Comfortaa !important;
  letter-spacing: initial;
}

a {
  font-family: Comfortaa !important;
}

.overlay {
    width: 100%;
    background: none;
    transition: 1s;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.9));
    bottom: 0;
}

.overlay span {
    color: white;
}

.picframe {
    border-radius: var(--border-radius);
}

.picframe img {
    vertical-align: top;
}

.picframe:hover img {
    transform: scale(0.90) translateY(10%);
}

.picframe:hover .overlay {
    transform: scale(0.9) translateY(10%);
}

#project-section {
    padding: 90px 0 90px 0 !important;
}

</style>
