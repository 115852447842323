import getters from "./getters.js";

export default {
  state() {
    return {
      isMobile: window.innerWidth / window.innerHeight < 1,
    };
  },
  getters,
};
