<template>
  <nav class="navbar" id="navbar">

<div
:class="`${
        showBackground !== undefined && showBackground == true
          ? 'GetBackground'
          : showBackground == false
          ? 'NoBackground'
          : ''
      }`">
</div>
    <li class="logo">
      <div class="position-logo">
        <router-link to="/"> TUES Fest Logo </router-link>
        <div
          @click="
            showNavbar = !showNavbar;
            toggled = !toggled;
          "
          class="burger"
          id="burger"
          :class="`${
            toggled !== undefined && toggled == true
              ? 'Toggled'
              : toggled == false
              ? 'NotToggled'
              : ''
          }`"
        >
          <div class="line1" id="line1"></div>
          <div class="line2" id="line2"></div>
          <div class="line3" id="line3"></div>
        </div>
      </div>
    </li>
    <ul
      id="mainUl"
      :class="`mainUl ${
        showNavbar !== undefined && showNavbar == true
          ? 'ShowNavbar'
          : showNavbar == false
          ? 'HideNavbar'
          : ''
      }`"
      alt=""
    >
      <li

        v-for="(route, index) in routes.filter((e) => !!e.linkText?.length)"
        :key="index"
        :style="`${index != routes.length - 1 ? 'margin-inline: 3%;' : ''}`"
      >
        <router-link class="links" :to="route.path">
          <span id="span-id">{{ route.linkText }}</span></router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
import routes from "../util/routes";

export default {
  name: "NavBar",
  data() {
    return {
      showBackground: false,
      routes,
      showNavbar: undefined,
      toggled: undefined,
      removeBackground: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.showBackground = true;
      });

    window.removeEventListener("scroll", () => {
      this.removeBackground = true;});
    
  },
  willUnmount() {
    window.removeEventListener("scroll");
  },
};
</script>

<style scoped>
.navbar {
  height: max-content;
  width: 100%;
  min-height: 4rem;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1001;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
}

#mainUl {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: max-content;
  margin: 0;
}

.position-logo {
  display: flex;
}

.logo a {
  background-image: url(../../public/assets/logo.svg);
  background-repeat: no-repeat;
  background-size: 160px;
  display: inline-block;
  position: relative;
  text-indent: -999999px;
  width: 300px;
background-position: center center;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transform: translate(0%, -15%);
}
li {
  height: max-content;
  display: flex;
}

a {
  justify-content: space-around;
  display: block;
  height: 100%;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-align: center;
  transition: 0.3s color ease-out;
}

a:hover {
  color: var(--purple);
}

#span-id {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.GetBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(15, 15, 15);
  transform: translateY(-100%);
  animation-name: getBackground;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: -1;
}

.NoBackground{
  background-color: transparent;
  transform: translateY(100%);
  animation-name: removeBackground;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.ShowNavbar {
  animation: showNavbar 0.5s ease-in-out 0s 1;
  animation-fill-mode: forwards;
  transform: translateX(0%);
}

.HideNavbar {
  transform: translate(100%, 0%);
  animation: hideNavbar 0.5s ease-in-out 0s 1;
}

@keyframes getBackground {
  to {
    transform: translateY(0%);
  }
}

@keyframes removeBackground {
  to {
    transform: translateY(100%);
  }
}

@keyframes showNavbar {
  to {
    transform: translateX(0%);
    z-index: -1;
    animation-play-state: paused;
  }
}

@keyframes hideNavbar {
  0% {
    transform: translateX(0%);
    z-index: -1;
  }
  100% {
    transform: translateX(100%);
    z-index: -1;
  }
}
.burger div {
  position: relative;
  width: 3vh;
  height: 0.5vh;
  background-color: white;
  margin: 5px;
  transition: all 0.3s ease;
}
.burger {
  display: none;
  cursor: pointer;
  transform: translate(0%, 25%);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
.burger {
  display: none !important;
}
.Toggled .line1 {
  transform: rotate(-45deg) translate(-8px, 8px);
}
.Toggled .line2 {
  opacity: 0;
}
.Toggled .line3 {
  transform: rotate(45deg) translate(-8px, -8px);
}

.Toggled {
  opacity: 0.5;
  display: flex;
}

@media screen and (max-width: 1230px) {
  body {
    overflow-x: hidden;
  }

  nav {
    display: inline-block;
  }

  #mainUl {
    position: absolute;
    right: 0px;
    flex-direction: column;
    background-color: rgb(15, 15, 15);
    transform: translateX(100%);
    height: 100vh;
    padding: 0;
    justify-content: space-evenly;
  }
  .logo {
    padding: 0;
    min-height: 0.2rem;
  }
  .burger {
    display: block !important;
  }
  .Mobile .logo {
    display: block;
  }

  #span-id {
    white-space: wrap;
    margin-inline: 1rem;
  }
  .position-logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-inline: 2rem;
  }
  .logo a {
    width: 170px;
    transform: translate(0%, 50%);
  }
}
</style>
