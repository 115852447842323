<template>
  <div class="AboutPage">
    <section
      class="speaker-detail"
      id="section-about"
    >
      <div class="wm wm-border dark"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col sp-profile-pic">
            <div class="about-card" style="width: 18rem">
              <img src="logo.webp" class="card-img-top" alt="TUES Fest" />
              <div class="card-body">
                <div class="social-icons-about">
                  <a target="_blank" href="https://www.facebook.com/tues.bg"
                    ><i class="fa fa-facebook fa-lg"></i
                  ></a> <a target="_blank" href="https://www.instagram.com/tues.bg/"
                    ><i class="fa fa-instagram fa-lg"></i
                  ></a> <a target="_blank" href="https://www.youtube.com/channel/UCQcbYkAKPEgfjzvwb2sUWSQ"
                    ><i class="fa fa-youtube-play youtube-icon fa-lg"></i
                  ></a> <a target="_blank" href="https://elsys-bg.org"
                    ><i class="fa fa-info-circle fa-lg"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="info-column col-lg-8 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="text-box">
                <h2 class="">За ТУЕС</h2>
                <p class="about-text">
                  Технологично училище “Електронни системи” към Технически Университет - София вече
                  над 30 години подготвя бъдещите лидери на ИТ сектора в България.
                </p>
                <h3 class="pb10">Специалности</h3>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/priem/specialnost-sistemno-programirane"
                        ><h4 class="id-color">Системно програмиране</h4></a
                      >
                      <div class="small-mod-border"></div>
                      <span>
                        Най-задълбочената училищна програма по програмиране в България, която е
                        незаменим трамплин за всеки с амбиции за кариера в ИТ сектора.
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/priem/specialnost-komputyrni-mreji"
                        ><h4 class="id-color">Компютърни мрежи</h4></a
                      >

                      <div class="small-mod-border"></div>
                      <span>
                        Първата крачка към вълнуващата инженерна професия. Ще учите за проектиране
                        на електроника, комуникации, мрежи, роботика и IoT.
                      </span>
                    </div>
                  </div>
                </div>
                <p class="pt10">
                  Възпитаниците на ТУЕС преминават през задълбочена и специализирана 5-годишна
                  програма, която им позволява да се позиционират възможно най-бързо в технологичния
                  сектор. Те са сред най-търсените и най-успешните професионалисти и предприемачи в
                  ИТ сектора у нас и в чужбина.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="align-items-center">
              <h1 class="study pb10">Обучение</h1>
              <h3 class="pb5">Освен специализирания учебен план ТУЕС предлага:</h3>
              <ul class="list-style-one">
                <li>
                  <i class="fa fa-check-circle-o check" aria-hidden="true" />
                  Разширено изучаване на английски език.
                </li>
                <li>
                  <i class="fa fa-check-circle-o check" aria-hidden="true" />
                  Учебни програми, които покриват изискванията за задължителна подготовка по
                  общообразователните предмети.
                </li>
                <li>
                  <i class="fa fa-check-circle-o check" aria-hidden="true" />
                  Извънкласни занимания:
                  <ul class="list-style-two">
                    <li style="margin-top: 10px">
                      <a
                        class="colored-text"
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/klubove/cisco-akademija"
                        >Cisco академия</a
                      >
                      за обучение в актуалните мрежови технологии;
                    </li>
                    <li>
                      <i class="fa fa-check-circle-o check" aria-hidden="true" />вградени
                      микроконтролерни системи на базата на ардуино;
                    </li>
                    <li>
                      <i class="fa fa-check-circle-o check" aria-hidden="true" />
                      <a
                        class="colored-text"
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/uchenicheski-jivot/nagradi"
                        >участие в национални и международни състезания</a
                      >
                      по програмиране, компютърни мрежи и роботика;
                    </li>
                    <li>
                      <i class="fa fa-check-circle-o check" aria-hidden="true" />
                      възможност за изява на ежегодното състезание по програмиране
                      <a
                        class="colored-text"
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/uchenicheski-jivot/hack-tues"
                        >Hack TUES</a
                      >
                      и изложението на ученически проекти
                      <a
                        class="colored-text"
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/priem/den-na-otvorenite-vrati"
                        >ТУЕС Фест</a
                      >;
                    </li>
                    <li>
                      <i class="fa fa-check-circle-o check" aria-hidden="true" />
                      <a
                        class="colored-text"
                        target="_blank"
                        rel="noopener"
                        href="https://elsys-bg.org/uchenicheski-jivot/inspiration-talks"
                        >работилници и лекции на различни теми</a
                      >, водени от професионалисти, завършили ТУЕС.
                    </li>
                  </ul>
                </li>
                <li>
                  <i class="fa fa-check-circle-o check" aria-hidden="true" />
                  Възможност за участие в
                  <a
                    class="colored-text"
                    target="_blank"
                    rel="noopener"
                    href="https://elsys-bg.org/uchenicheski-jivot/ekskurzii?page=1"
                    >зелено и бяло училище</a
                  >.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-fun-facts-2" class="text-light">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-xs-6" data-wow-delay=".5s">
            <div class="de_count">
              <i class="fa fa-graduation-cap fa-lg id-color-2"></i>
              <h3>2846</h3>
              <span class="text-white">Завършили ученици</span>
            </div>
          </div>

          <div class="col-md-3 col-xs-6" data-wow-delay=".5s">
            <div class="de_count">
              <i class="fa fa-trophy fa-lg id-color-2"></i>
              <h3>87</h3>
              <span class="text-white">Награди и отличия</span>
            </div>
          </div>

          <div class="col-md-3 col-xs-6" data-wow-delay=".5s">
            <div class="de_count">
              <i class="fa fa-briefcase fa-lg id-color-2"></i>
              <h3>140</h3>
              <span class="text-white">Стажа на година</span>
            </div>
          </div>

          <div class="col-md-3 col-xs-6" data-wow-delay=".5s">
            <div class="de_count">
              <i class="fa fa-history fa-lg id-color-2"></i>
              <h3>32</h3>
              <span class="text-white">Годишна история</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="speaker-detail tues-success">
      <div class="auto-container">
        <div class="row">
          <div class="info-column col-lg-12 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="text-box">
                <h3 class="">ТУЕС постига успехите си благодарение на:</h3>
                <ul class="list-style-one">
                  <li>
                    <i class="fa fa-check-circle-o check" aria-hidden="true" />
                    тясната
                    <a
                      class="colored-text"
                      target="_blank"
                      rel="noopener"
                      href="https://elsys-bg.org/obuchenie/integracija-s-tehnicheskija-uniersitet"
                      >интеграция с Технически университет - София</a
                    >;
                  </li>
                  <li>
                    <i class="fa fa-check-circle-o check" aria-hidden="true" />гъвкавия си
                    специализиран учебен план;
                  </li>
                  <li>
                    <i class="fa fa-check-circle-o check" aria-hidden="true" />преподаватели от
                    университета и ИТ практици;
                  </li>
                  <li>
                    <i class="fa fa-check-circle-o check" aria-hidden="true" />
                    активната роля
                    <a
                      class="colored-text"
                      target="_blank"
                      rel="noopener"
                      href="https://elsys-bg.org/uchilishteto/asociacija-na-zavyrshilite-tues"
                      >на завършилите</a
                    >
                    в живота на училището;
                  </li>
                  <li>
                    <i class="fa fa-check-circle-o check" aria-hidden="true" />
                    тясната
                    <a
                      class="colored-text"
                      target="_blank"
                      rel="noopener"
                      href="https://elsys-bg.org/obuchenie/partniorstvo-s-biznesa"
                      >връзка с реалния бизнес</a
                    >
                    за учебни и извънучебни занимания,
                    <a
                      class="colored-text"
                      target="_blank"
                      rel="noopener"
                      href="https://elsys-bg.org/obuchenie/uchebna-praktika-po-specialnostta"
                      >практики</a
                    >
                    по специалността,
                    <a
                      class="colored-text"
                      target="_blank"
                      rel="noopener"
                      href="https://elsys-bg.org/obuchenie/diplomna-rabota"
                      >дипломни проекти</a
                    >
                    и др.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="apply-section" class="error-section event-detail apply-section">
      <div class="auto-container-ht">
        <div class="image-with-caption">
          <img alt="" src="/assets/HackTUES.webp" class="img-fluid rounded mb30" />
        </div>
        <div class="content-box">
          <h2 class="">Hack TUES</h2>
          <p class="about-text">
            &emsp;<a href="https://www.hacktues.com/" target="_blank"
              ><b class="id-color-2">Hack TUES</b></a
            >
            е едно от ключовите събития за ТУЕС, в което ученици от училището в отбори от по 3 - 5
            участници в рамките на два дни създават от нулата свой ИТ проект по зададена тема и след
            това го представят пред професионално жури от преподаватели и ИТ специалисти.
            <br />
            &emsp;Хакатонът дава възможност на участниците да усъвършенстват уменията си по
            програмиране, работа в екип и презентация на готовия проект. Те работят под менторството
            на специалисти от ИТ бизнеса, като понякога тези познанства прерастват в предложения за
            практика и стаж. Организаторите, от своя страна, научават много за процеса на
            организиране на подобно събитие и силно развиват своите меки умения. Общувайки със
            спонсорите, журито, менторите и доброволците, те си изграждат ясна представа за реалната
            работна обстановка.
            <br />
            &emsp;Това е първият и единствен по рода си хакатон в България, организиран от ученици
            за ученици. Събитието стартира през 2015 г. и досега има седем издания. Всяка година
            Hack TUES се организира от координационен екип ученици от 11. клас, който се грижи за
            цялостната организация на събитието под менторството на
            <a href="https://aztues.bg/" target="_blank"><b class="colored-text">АЗТУЕС</b></a> и
            ръководството на ТУЕС.
            <br />
            &emsp;Прочетете невероятната история на последното му издание с мото Technology synced
            with nature
            <a
              href="https://www.elsys-bg.org/blog/hack-tues-gg-tehnologii-v-sinhron-s-prirodata-467"
              target="_blank"
              ><b class="colored-text">тук.</b></a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
.AboutPage {
  text-align: left;
}

h3,
p,
h2,
h1,
h4 {
  text-transform: initial;
  font-family: Comfortaa !important;
  letter-spacing: initial;
}

.small-mod-border {
  width: 100%;
}

.id-color {
  text-align: center;
}

li::before {
  all: unset;
}

.check {
  color: var(--secondary-color-1);
  position: absolute;
  left: 0.5vh;
  top: 0.5vh;
}

#apply-section {
    color: white;
    text-align: left;
}

.speaker-session-info {
    color: rgb(255, 255, 255);
}
</style>
