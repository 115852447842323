<template>
  <section id="section-about">
    <div class="wm wm-border dark"></div>
    <div class="container">
      <div class="row `">
        <div class="col-lg-6">
          <h2 class="id-color">За събитието</h2>
          <p class="about-text">
            &emsp; <b class="colored-text">ТУЕС Фест</b> (в първите си издания наричан Ден на
            отворените врати на ТУЕС) е ежегодно изложение на проекти, курсови и дипломни работи в
            областта на софтуера, компютърните мрежи и вградените системи и роботиката, създадени от
            талантливите ученици на
            <a href="https://elsys-bg.org/" target="_blank"
              ><b class="colored-text"
                >Технологично училище „Електронни системи“ (ТУЕС) към Технически университет –
                София.</b
              ></a
            >
            <!-- Събитието за втора поредна година се провежда в изцяло онлайн формат. 48 часа ТУЕС ви
            предоставя възможност на 24-ти и 25-ти април 2021 г. да се запознаете с училището, с
            неговия уникален образователен модел и с възпитаниците му. <br /><br /> -->
            &emsp; Каним ви да разгледате интересните проекти, които учениците са подготвили.
            Гледайте и панелните дискусии с ученици, преподаватели и завършили туесари.
            <br /><br />
            &emsp; Смятаме, че най-добрият начин да се представи училището е именно чрез успехите на
            учениците, които учат в него. За ТУЕС връзката с ИТ бизнеса е неизбежна и основополагаща
            вече повече от 30 години. Освен професионална подготовка възпитаниците му получават и
            близост с професионалисти от големите компании в ИТ сектора, които следят развитието на
            учениците и подкрепят редовно инициативите на ТУЕС, една от които е именно ТУЕС Фест.
          </p>

          <router-link to="/about" class="btn-custom font-weight-bold text-white sm-mb-30 scroll-to"
            >Прочетeте повече за ТУЕС</router-link
          >
          <div class="spacer50"></div>
          <p class="about-text">
            &emsp;Вижте програмата на
            <router-link to="/schedule"><b class="colored-text">ТУЕС Фест 2022</b></router-link> и следете
            събитието във
            <a href="https://www.facebook.com/events/770800927194106" target="_blank"
              ><b class="colored-text">Facebook</b></a
            >
            за актуални новини! Разгледайте невероятните проекти на учениците.
            <br />
          </p>
          <!--<a href="/projects" class="btn-custom font-weight-bold text-white sm-mb-30 scroll-to"
            >Разгледайте проектите</a
          > -->
        </div>

        <div class="col-lg-6 mb-sm-30 text-center">
          <div class="de-images">
            <img class="img-fluid" src="/assets/about/about-image.webp" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
#section-about {
  color: white !important;
}

h2,
p,
a {
  font-family: Comfortaa !important;
  letter-spacing: initial;
}

.btn-custom {
  text-align: center !important;
}

.img-fluid:hover {
  transform: unset;
}
</style>
