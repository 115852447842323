<template> 
    <div id="wrapper">
        <div id="content" class="no-bottom no-top">
        <section id="apply-section" class="error-section event-detail apply-section">
                <div class="auto-container">
                <div class="image-with-caption">
                    <a href="https://elsys-bg.org/priem/red-i-uslovija-za-priem" target="_blank">
                        <img alt="Ред и условия за прием" src="../../public/assets/apply.jpg" class="img-fluid rounded mb30">
                    </a>
                    </div>
                    <div class="content-box">
                        <h2 class="colored-text">Защо ТУЕС?</h2>
                        <p class="apply-text">
                            Технологично училище „Електронни системи“ към Технически Университет - София 
                            е специализирано технологично училище от национално значение, което подготвя бъдещите
                            лидери на ИТ сектора в България и отвъд.
                            <br><br>
                            <strong>Кое прави ТУЕС уникално училище?</strong>
                        </p>
                        <ul class="list-style-one">
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            Специализиран учебен план
                            </li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            Училище интегрирано във ВУЗ</li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            Преподават завършили ТУЕС</li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            Тясна връзка с ИТ бранша</li>
                        </ul>
                        <p>Научете повече за образователния модел на училището <a href="https://tues.bg/priem/zashto-da-izbera-tues" target="_blank" rel="noopener">
                        <p>Научете повече за приема в ТУЕС <a href="https://tues.bg/priem/red-i-uslovija-za-priem" target="_blank" rel="noopener"><b class="colored-text">тук.</b></a></p></a></p>
                    </div>
                    <div class="image-with-caption">
                        <a target="_blank" href="https://elsys-bg.org/priem/zashto-da-izbera-tues">
                            <img alt="Защо ТУЕС" src="../../public/assets/why_tues.jpg" class="img-fluid rounded mb30"> 
                        </a>
                    </div>
                    <div class="content-box">
                        <h2 class="colored-text">Как да кандидатствам?</h2>
                        <p class="apply-text"><strong>За учебната 2021/2022 година</strong> в ТУЕС към ТУ-София ще се приемат ученици по следните специалности:</p>
                        <ul class="list-style-one">
                          <li>
                          <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            Системно програмиране с код 4810201 – 3 паралелки, всяка по 26 ученици</li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            Компютърни мрежи с код 5230502 – 1 паралелка от 26 ученици</li>
                        </ul>
                        <br>
                        <p class="apply-text"><strong>Балът се образува от сбора на:</strong></p>
                        <ul class="list-style-one">
                          <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            оценката по <strong>български език и литература</strong> от националното външно оценяване <strong>(БЕЛ от НВО)</strong></li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            <strong>3 пъти</strong> оценката по <strong>математика (Мат от НВО)</strong></li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            оценката по математика от свидетелството за завършен 7. клас <strong>(Мат 7кл.)</strong></li>
                            <li>
                            <i class="fa fa-check-circle-o check" aria-hidden="true" />
                            оценката по физика от свидетелството за завършен 7. клас <strong>(Физ 7кл.)</strong></li>
                        </ul>
                        <p>Научете повече за приема в ТУЕС <a href="https://tues.bg/priem/red-i-uslovija-za-priem" target="_blank" rel="noopener"><b class="colored-text">тук.</b></a></p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      message: 'Hello World!'
    }
  }
}
</script>

<style scoped>
.AboutPage {
  text-align: left;
  
}

h3,
p,
h2,
h1,
h4,
strong {
  text-transform: initial;
  font-family: Comfortaa !important;
  letter-spacing: initial;
  font-weight: bold;
}


.small-mod-border {
  width: 100%;
    font-weight: bold;
}

.id-color {
  text-align: center;
}

li::before {
  all: unset;
}

.check {
  color: var(--secondary-color-1);
  position: absolute;
  left: 0.5vh;
  top: 0.5vh;
    font-weight: normal;
}

#apply-section {
    color: white;
    text-align: left;
  font-weight: bold;
}

.speaker-session-info {
    color: rgb(255, 255, 255);
}
</style>