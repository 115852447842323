<template>
  <loading v-if="this.$store.getters.isLoading" />
  <section v-else id="projects-section" class="projects-section features-section-two" style="">
    <div class="col-md-6 offset-md-3 text-center fadeInUp">
      <h1>Проекти</h1>

      <div class="spacer-single"></div>
      <h4>
        Представените проекти са разделени в три категории според вида си. Изберете коя искате да
        разгледате:
      </h4>
      <div class="separator">
        <br />
      </div>
      <div class="form-outline">
        <input
          v-model="formText"
          type="search"
          id="form1"
          class="form-control"
          placeholder="Търси проект.."
          aria-label="Search"
        />
      </div>

      <div class="spacer-single"></div>
    </div>

    <div class="projects-button-container">
      <span
        @click="
          this.$store.commit('setCurrentProjectCategory', { currentProjectCategory: 'Robotics' })
        "
        data-filter=".embedded-project"
        class="embedded-block feature-block-two pt15 col-lg-4 col-md-6 col-sm-12 fadeInUp animated"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div :class="embeddedStyle">
          <h2>Роботика</h2>
        </div>
      </span>

      <span
        @click="
          this.$store.commit('setCurrentProjectCategory', { currentProjectCategory: 'Software' })
        "
        data-filter=".software-project"
        class="software-block feature-block-two pt15 col-lg-4 col-md-6 col-sm-12 fadeInUp animated"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div :class="softwareStyle">
          <h2>Софтуер</h2>
        </div>
      </span>

      <!-- Feature Block -->
      <span
        @click="
          this.$store.commit('setCurrentProjectCategory', { currentProjectCategory: 'Networks' })
        "
        data-filter=".networks-project"
        class="networks-block feature-block-two pt15 col-lg-4 col-md-6 col-sm-12 fadeInUp animated"
        style="visibility: visible; animation-name: fadeInUp"
      >
        <div :class="networksStyle">
          <h2>Компютърни мрежи</h2>
        </div>
      </span>
    </div>
    <div id="projects-container" class="projects-container">
      <div
        v-for="(project, key) in filteredProjects"
        :key="key"
        class="embedded-project pricing-s1 news-block mt30"
      >
        <div class="top">
          <router-link :to="`/projects/${getProjectIndexById(project.id)}`"
            ><h2>{{ project.name }}</h2></router-link
          >
          <router-link
            :to="`/projects/${getProjectIndexById(project.id)}`"
            v-if="hasThumbnail(getProjectIndexById(project.id))"
            ><img :src="`/assets/project-pictures/${project.pictures[0]}.webp`" alt=""
          /></router-link>
        </div>
        <div class="bottom">
          <router-link
            :to="`/projects/${getProjectIndexById(project.id)}`"
            style="font-family: Comfortaa"
            class="btn-custom font-weight-bold text-white sm-mb-30"
            >Разгледай проекта</router-link
          >
          <!-- <button
            :disabled="isVoted ? this.$store.getters.votedFor.includes(project.id) ? false : isVoted : false"
            :style="`font-family: Comfortaa;`"
            class="vote-button btn-custom font-weight-bold text-white sm-mb-30"
            @click="toggleProjectVote(project)"
            > {{ isVoted ? this.$store.getters.votedFor.includes(project.id) ? "Отмени избор за глас" : "Избран е проект от тази категория" : "Избери за гласуване"}} </button
          >
          -->
          <!-- <div class="btn-custom font-weight-bold text-white sm-mb-30">
            Гласувай
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import projects from "../util/pageTexts/projects.js";
import getProjectIndexById from "../util/getProjectIndexById";
import Loading from "../components/Loading.vue";

export default {
  name: "ProjectsPage",
  data() {
    return {
      projects,
      currentlySelected: "Robotics",
      formText: "",
    };
  },
  components: {
    Loading,
  },
  computed: {
    filteredProjects() {
      if (this.formText == "") {
        return this.$store.getters.projects.filter((project) => {
          var projectCategory = this.getCategory(project.category);
          if (projectCategory == this.$store.getters.currentProjectCategory) {
            return project;
          }
        });
      } else {
        return this.$store.getters.projects.filter((project) => {
          if (project.name.toLowerCase().includes(this.formText.toLowerCase())) {
            return project;
          }
        });
      }
    },
    embeddedStyle() {
      if (this.$store.getters.currentProjectCategory == "Robotics") {
        return {
          "active-embedded": true,
          "software-logo-fix": true,
          "projects-button": true,
          "inner-box": true,
        };
      } else {
        return {
          embedded: true,
          "software-logo-fix": true,
          "projects-button": true,
          "inner-box": true,
        };
      }
    },
    softwareStyle() {
      if (this.$store.getters.currentProjectCategory == "Software") {
        return {
          "active-software": true,
          "software-logo-fix": true,
          "projects-button": true,
          "inner-box": true,
        };
      } else {
        return {
          software: true,
          "software-logo-fix": true,
          "projects-button": true,
          "inner-box": true,
        };
      }
    },
    networksStyle() {
      if (this.$store.getters.currentProjectCategory == "Networks") {
        return {
          "active-networks": true,
          "software-logo-fix": true,
          "projects-button": true,
          "inner-box": true,
        };
      } else {
        return {
          networks: true,
          "software-logo-fix": true,
          "projects-button": true,
          "inner-box": true,
        };
      }
    },
    isVoted() {
      if (this.$store.getters.currentProjectCategory == "Robotics") {
        return !(this.$store.getters.embedded == "");
      } else if (this.$store.getters.currentProjectCategory == "Software") {
        return !(this.$store.getters.software == "");
      } else if (this.$store.getters.currentProjectCategory == "Networks") {
        return !(this.$store.getters.networks == "");
      }
      return false;
    },
  },
  methods: {
    getProjectIndexById,
    getCategory(bgCategory) {
      if (bgCategory == "Роботика и вградени микрокомпютърни системи") {
        return "Robotics";
      }
      if (bgCategory == "Софтуер") {
        return "Software";
      }
      return "Networks";
    },
    hasThumbnail(projectIndex) {
      return !!projects[projectIndex]?.pictures?.length;
    },
    toggleProjectVote(project) {
      // console.log("cureentProjectCategory: " + this.$store.getters.currentProjectCategory)
      let payload = {
        type: this.$store.getters.currentProjectCategory,
        name: project.name,
        id: project.id,
      };
      this.$store.commit("setVoteProject", payload);
    },
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

.btn-custom {
  margin-bottom: 0px;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.vote-button {
  all: unset;
  cursor: pointer;
  background-color: transparent;
  /* margin-top: 40px; */
  margin-bottom: 40px;
  margin-right: 40px;
  margin-left: 40px;
  width: auto;
  letter-spacing: 3px;
  font-size: 18px;
  border-radius: 10px;
  padding: 10px 30px;
  text-decoration: none;
  text-transform: uppercase;
}

.vote-button:hover {
  transition: 0.5s;
  background-color: rgb(55, 15, 59);
}

h1,
h2,
h3,
h4,
h5,
h6,
.schedule-item .sc-info h3 {
  font-weight: 800;
}

h1,
h2,
h3,
h4,
h5,
h6,
.big-white,
.ultra-big-white,
.de_tab.tab_steps .de_nav li span,
.teaser-text,
.text-slider,
#mainmenu,
.btn,
a.btn,
.btn-line,
a.btn-line,
#filters,
.ultra-big-white,
.wm,
blockquote.testimonial-big.s2 .title,
.tp-title,
.countdown-amount,
.countdown-period,
.de_count h3,
.ultra-big-2,
.tp-caption,
.pricing-s1.light .top .price b,
blockquote.testimonial-big .title,
#mo-menu {
  font-family: Comfortaa;
}

#form1 {
  text-align: center;
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
}

#projects-section {
  padding: 90px 0px;
}

@media (max-width: 991.98px) {
  #form1 {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<style scoped>
.dark-mode div.modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
