export default {
  alphaSponsors: {
    name: "Алфа Спонсори",
    folder: "/assets/sponsors/",
    items: [
      {
        company: "A1",
        logoSrc: "a1.webp",
        site: "https://www.a1.bg/bg?home",
        padding: "3.3vh",
      },
      {
        company: "Appolica",
        logoSrc: "appolica.svg",
        site: "https://appolica.com/",
        padding: "0vh",
      },
      {
        company: "CNSys",
        logoSrc: "cnsys.svg",
        site: "https://cnsys.bg/",
      },
      {
        company: "Droxic",
        logoSrc: "droxic.svg",
        site: "https://droxic.com/en",
        padding: "0vh",
      },
      {
        company: "Imperia Online",
        logoSrc: "imperia-online.webp",
        site: "https://imperiaonline.bg/bg/",
        padding: "1.5vh",
      },
      {
        company: "Isobar",
        logoSrc: "isobar.svg",
        site: "https://www.isobar.com/",
        padding: "2vh",
      },
      {
        company: "M2M",
        logoSrc: "m2m.svg",
        site: "https://m2mservices.com/",
        padding: "4.5vh",
      },
      {
        company: "Nettera ",
        logoSrc: "nettera.webp",
        site: "https://neterra.net/bg",
        padding: "1.5vh",
      },
      {
        company: "Progress",
        logoSrc: "progress.svg",
        site: "https://www.progress.com/",
        padding: "1vh",
      },
      {
        company: "Schwarz IT Hub",
        logoSrc: "schwarz.svg",
        site: "https://it.schwarz/en",
        padding: "0vh",
      },
      {
        company: "TelebidPro",
        logoSrc: "telebidpro.svg",
        site: "https://telebid-pro.com/",
        padding: "2.5vh",
      },
      {
        company: "VMWare",
        logoSrc: "vmware.svg",
        site: "https://www.vmware.com/",
        padding: "1.5vh",
      },
    ],
  },
  betaSponsors: {
    name: "Бета Спонсори",
    folder: "/assets/sponsors/",
    items: [
      {
        company: "Acronis",
        logoSrc: "acronis.svg",
        site: "https://www.acronis.com/",
        padding: "4vh",
      },
      {
        company: "CPD",
        logoSrc: "cpd.svg",
        site: "http://web.cpdbg.com/",
        padding: "4vh",
      },
      {
        company: "Devrix",
        logoSrc: "devrix.svg",
        site: "https://devrix.com/",
        padding: "4vh",
      },
      {
        company: "GTT",
        logoSrc: "gtt.svg",
        site: "https://www.gtt.net/us-en",
        padding: "4.5vh",
      },
      {
        company: "Haemimont AD",
        logoSrc: "haemimont.webp",
        site: "http://www.haemimont.com/",
        padding: "0vh",
      },
      {
        company: "Limechain",
        logoSrc: "limechain.svg",
        site: "https://limechain.tech/",
        padding: "1.5vh",
      },
      {
        company: "Ocado Technology",
        logoSrc: "ocado-technology.webp",
        site: "https://www.ocadogroup.com/about-us/ocado-technology",
        padding: "2vh",
      },
      {
        company: "Telelink Business Services",
        logoSrc: "telelink.svg",
        site: "https://www.tbs.tech/",
        padding: "2.5vh",
      },
      {
        company: "ММ-Solutions",
        logoSrc: "mm-solutions.svg",
        site: "https://www.mm-sol.com/",
        padding: "0vh",
      },
    ],
  },
  gammaSponsors: {
    name: "Гама Спонсори",
    folder: "/assets/sponsors/",
    items: [
      {
        company: "Konica Minolta",
        logoSrc: "konica-minolta.svg",
        site: "https://www.konicaminolta.bg/bg-bg",
        padding: "1vh",
      },
      {
        company: "Strypes",
        logoSrc: "strypes.svg",
        site: "https://strypesgroup.com/",
        padding: "4vh",
      },
      {
        company: "Trading 212",
        logoSrc: "trading212.svg",
        site: "https://www.trading212.com/",
      },
      {
        company: "Ubisoft",
        logoSrc: "ubisoft.svg",
        site: "https://www.ubisoft.com",
        padding: "3vh",
      },
    ],
  },
  partners: {
    name: "Партньори",
    folder: "/assets/partners/",
    items: [
      {
        company: "ТУЕС",
        logoSrc: "elsys.svg",
        site: "https://elsys-bg.org",
        padding: "5vh",
      },
      {
        company: "АЗТУЕС",
        logoSrc: "aztues.svg",
        site: "https://aztues.bg/",
      },
      {
        company: "Смартком",
        logoSrc: "smartkom.webp",
        site: "https://smartcom.bg/",
      },
      {
        company: "Sofia Tech Park",
        logoSrc: "sofia-tech-park.webp",
        site: "https://sofiatech.bg/",
        padding: "3vh",
      },
      {
        company: "Multivisia",
        logoSrc: "multivisia.webp",
        site: "https://www.multivisia.com/bg/",
        padding: "3vh",
      },
      {
        company: "Ora",
        logoSrc: "ora.webp",
        site: "https://ora.pm/",
        padding: "3.5vh",
      },
      {
        company: "Bulged",
        logoSrc: "bulged.webp",
        site: "https://bulged.net",
        padding: "3.5vh",
      },
      {
        company: "Comet Electronics",
        logoSrc: "comet-electronics.svg",
        site: "https://www.comet.bg/",
        padding: "3.5vh",
      },
      {
        company: "FRudada",
        logoSrc: "frudada.webp",
        site: "https://www.frudada.com/",
        padding: "3.5vh",
      },
      {
        company: "Зайо Байо",
        logoSrc: "zaio-baio.webp",
        site: "https://zayobayo.bg/",
        padding: "1vh",
      },
    ],
  },
  mediajury: {
    name: "Медиа Жури",
    folder: "/assets/media/",
    items: [
      {
        company: "БНТ 2",
        logoSrc: "bnt.svg",
        site: "https://www.bnt.bg/",
        padding: "1vh",
      },
      {
        company: "БТВ",
        logoSrc: "btv.png",
        site: "https://www.btv.bg/",
        padding: "4vh",
      },
      {
        company: "Инженер бг",
        logoSrc: "injiner.svg",
        site: "https://xn--e1aabhzcw.bg/",
      },
      {
        company: "Форбс България",
        logoSrc: "forbes.svg",
        site: "https://forbesbulgaria.com/",
      },
      {
        company: "Капитал Кариери",
        logoSrc: "karieri.webp",
        site: "https://www.karieri.bg/",
        padding: "1vh",
      },
      {
        company: "Списание Мениджър",
        logoSrc: "menidjur.svg",
        site: "https://spisanie.manager.bg/",
        padding: "3vh",
      },
      {
        company: "Успелите.бг",
        logoSrc: "uspelite.svg",
        site: "https://uspelite.bg/",
        padding: "1vh",
      },
      {
        company: "Robotics-Bulgaria",
        logoSrc: "robotics-bulgaria.svg",
        site: "https://robotics-bulgaria.com/",
        padding: "2vh",
      },
      {
        company: "Industry Info",
        logoSrc: "IndustryInfo.svg",
        site: "https://industryinfo.bg/",
        padding: "2vh",
      },
      {
        company: "DevStyleR",
        logoSrc: "DevStyleR.png",
        site: "https://devstyler.bg/",
      },
    ],
  },
};
