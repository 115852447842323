export default [
  {
    img: "tanya.webp",
    testimonyBody: `ТУЕС направи прехода от детството към възрастността за нас да е прекрасен,
    романтичен и приключенски период на приятелства, близост, сплотеност, веселби и
    сълзи, в една променлива и нестабилна, но много дружелюбна и грижовна среда. Даде ни
    основа да се формираме като личности, които умеят да ценят себе си, околните, труда
    и усилията да пребориш проблемите, и радостта да посрещнеш успехите.`,
    testimonyName: `Таня Динева, випуск 1995, Team Lead Senoir QA в Astea Solutions`,
  },

  {
    img: "zarko.webp",
    testimonyBody: `Скоро след като влязох в ТУЕС осъзнах, че са ме приели не в средно училище, а
    направо в нещо като университет. Беше много по-сериозно и по-практически насочено –
    и това адски ми харесваше. Всичко се навърза и ми стана 10 пъти по-интересно, защото
    вече имах самочувствието, че знам на принципно ниво как функционира всичко
    електронно около нас.`,
    testimonyName: `Светозар Георгиев, випуск 1995 - съосновател на Телерик, Телерик Академия и
      Кампус X`,
  },
  {
    img: "vicky.webp",
    testimonyBody: `В ТУЕС развих знания и умения за актуалните тогава технологии, утвърдих
    аналитичното си мислене и доразвих възприятието си за света. Срещнах професионалисти
    в различни области в лицето на преподавателите ни и истински приятели, които и до
    днес са важна част от живота ми.`,
    testimonyName: `Виктория Антонова, випуск 2006, Sr remote site monitor в PPD`,
  },
  {
    img: "veselin.webp",
    testimonyBody: `ТУЕС и неговият уникален модел са основа на IT бранша в България. Нивото на
    училището винаги е било високо – изискванията към самодисциплината и
    самоорганизацията на учениците са съизмерими с тези на студентите в Техническия
    университет.`,
    testimonyName: `Веселин Александров, випуск 1996 - Изпълнителен Директор на Софтуер АГ ДЦ
      България`,
  },
  {
    img: "radi.webp",
    testimonyBody: `ТУЕС за мен е символ на братство – независимо къде, по какъв повод и в каква
    ситуация се засичам с туесари, винаги успяваме да се разберем с две думи. Знам, че
    мога да разчитам на човека срещу мен. Усеща се чувството за принадлежност към едно
    сплотено „общество“.`,
    testimonyName: `Радостина Герчева, випуск 2007, Telecommunications and data networks expert,
      Smartcom`,
  },
  {
    img: "mitko.webp",
    testimonyBody: `ТУЕС ми даде една невероятна база от знания, която ми позволи да избера правилния
    за мен път за бъдещото ми развитие и спомогна за моя успех. Това е мястото, което
    многократно ми разшири кръгозора на знанията в ИТ областта, задоволявайки моето
    любопитство и желанието ми да разбера всичко за компютрите.`,
    testimonyName: `Димитър Асенов, випуск 2005 - старши софтуерен инженер в Google, Швейцария.`,
  },
  {
    img: "plamen-petrov.webp",
    testimonyName:
      "Пламен Петров,  випуск  2005 - \nРъководител Разработка на софтуерни продукти в Experian",
    testimonyBody:
      "ТУЕС се оказа едно от най-важните решения в живота ми до момента. Несъмнено държи и първото място като най-точното попадение по отношение на образованието ми в ИТ посока. За мен ТУЕС е място, където попаднах в чудесна среда, създадох приятелства и научих занаят. Пет прекрасни, усмихнати години на задоволяване на любопитството ми към технологиите.",
  },
  {
    img: "kiril-mitov.webp",
    testimonyName: "Кирил Митов, випуск 2004 - президент на Axlessoft",
    testimonyBody: "ТУЕС бе едно от най-важните неща, които съм правил.",
  },
  {
    img: "alexander-bonev.webp",
    testimonyName: "Александър Бонев, випуск 1995 - директор инженерни услуги във VMWare",
    testimonyBody:
      "ТУЕС ми даде както хляб ръцете, така и хоби - прекарвам по 12-14 часа зад екрана всеки ден  Даде ми и много приятели, с които поддържаме здрава връзка. Значи много за мен, това е! А спомени много, трудно е да избера. И все пак..",
  },
  {
    img: "alexander-tsokev.webp",
    testimonyName:
      "доц. д-р инж. Александър Цокев - випуск 1997, ръководител  “Advanced Security Operations Center” в Teleink Business Services",
    testimonyBody:
      "За мен ТУЕС е … „ТУЕС“. Трудно е да се определи с няколко изречения, защото това беше много повече от училище. Това бяха едни изключителни години, свързани с придобиване на знания и израстване, приятелства и старт в кариерата. ТУЕС не беше просто училище, а място където ходихме с желание и хъс. Поглеждайки назад и вече през призмата на отминалите години оценявам колко много са ни дали всички преподаватели и служители от ТУЕС – не е лесно да спечелиш и да запазиш вниманието и уважението на класове с младежи в една трудна възраст. „ТУЕС“ се превърна в нарицателно, както и „ТУЕСАР“, а за всички които сме завършили това страхотно място това носи значение, което не е лесно да се опише с думи.",
  },
];
