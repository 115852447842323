<template>
  <section id="section-testimonials" aria-label="section">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div id="testimonial-carousel-single">
            <blockquote
              class="testimonial-big"
              v-for="(testimony, index) in testimonials.filter(
                (_, index) => index == testimonyIndex
              )"
              :key="index"
            >
              <div class="sc-pic">
                <img :src="`/assets/testimonials/${testimony.img}`" class="img-circle" alt="" />
              </div>
              “{{ testimony.testimonyBody }}”
              <span class="name">{{ testimony.testimonyName }}</span>
            </blockquote>
            <button class="Control" @click="decrement">{{ `\<` }}</button> <button class="Control" @click="increment">{{ `>` }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import testimonials from '../util/pageTexts/testimonials';
import shuffleArray from '../util/shuffleArray';

export default {
  name: "Testimonials",
  data() {
    return {
      testimonials: shuffleArray(testimonials),
      testimonyIndex: 0,
    };
  },
  methods: {
    increment() {
      this.testimonyIndex = (this.testimonyIndex + 1) % testimonials.length;
    },
    getPrevIndex(index, array) {
      return index - 1 < 0 ? array.length - 1 : index - 1;
    },
    decrement() {
      this.testimonyIndex = this.getPrevIndex(this.testimonyIndex, this.testimonials);
    },
  },
};
</script>

<style scoped>
.Control {
    all:unset;
    background: none;
    border: none;
    color: white;
    background-color: var(--secondary-color-1);
    padding: 10px;
    font-size: 4vh;
    transition: background 0.3s ease;
    cursor: pointer; 
    border-radius: 0.1vh;
}

.Control:hover {
    background-color: var(--pink);
}

.name {
  font-family: Comfortaa !important;
}
</style>